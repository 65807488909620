.features{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
    line-height: 1.6;
  }
  .features-item-main{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 50px;
  }
  .features-item {
    margin: auto;
    display: flex;
    padding: 10px;
    justify-content: center;
    align-items: center;
    gap: 40px;
    width: 490px;
  }

  .feat-desc {
    width: 90%;
  }
  
  .feat-logo {
    margin-left: -30px;
    width: 150px;
    height: auto;
  }

  .features p{
    font-family: Montserrat;
    font-size: medium;
    color:black ;
    text-align: left;
  }
  .features h1{
    font-family: Montserrat;
    font-size: large;
    font-weight: bold;
    color:black ;
    text-align: left;
    text-transform: uppercase;
  }
  .features h2{
    font-family: Montserrat;
    font-size: 50px;
    font-weight: bold;
    color:black ;
    text-align: left;
  }

  @media (max-width: 768px){
    .features-item-main{
        flex-direction: column;
        gap: 0px;
    }
    .features-item{
        width: auto;
    }
  }