.works-logo {
    margin-left: -30px;
    width: 100px;
    height: auto;
  }

  .works p{
    font-family: Montserrat;
    font-size: medium;
    color:rgb(0, 0, 65) ;
    text-align: left;
    line-height: 1.6;
  }
  .works h1{
    font-family: Montserrat;
    font-size: large;
    font-weight: bold;
    color:rgb(0, 0, 65) ;
    text-align: left;
    text-transform: uppercase;
  }
  .works h2{
    font-family: Montserrat;
    font-size: 50px;
    font-weight: bold;
    color:rgb(0, 0, 65) ;
    text-align: left;
  }